<template @keyup.enter="handleEnter">
  <v-container>
    <v-col>
      <!-- FLASHCARD -->
      <div>
        <v-alert type="success" v-if="okAlert">Poprawna odpowiedź!</v-alert>
        <v-alert type="error" v-if="badAlert"
          >Zła odpowiedź! Poprawna to: <b>{{ back }}</b></v-alert
        >

        <div v-if="showWordlist">
          <v-progress-linear
            :value="(currentSession / (session.length - 1)) * 100"
            height="10"
            striped
            rounded
          ></v-progress-linear>
          <v-card class="mt-4">
            <v-data-table
              :headers="headers"
              :items="session[currentSession]"
              hide-default-footer
              no-results-text="Brak wyników."
              no-data-text="Lista jest pusta."
              :disable-pagination="true"
            ></v-data-table>
          </v-card>

          <div class="float-right mt-2 mb-2">
            <v-btn text class="mr-3" @click="finishAndSaveProgress"
              >Koniec na dziś</v-btn
            >

            <v-btn
              color="primary"
              @click="
                shuffle();
                showWordlist = false;
              "
              >Dalej</v-btn
            >
          </div>
        </div>

        <div v-else>
          <v-card class="justify-center mt-2">
            <v-card-title class="justify-center">{{ front }}</v-card-title>
            <v-card-text>
              <v-text-field
                label="Odpowiedź"
                outlined
                focused
                v-model="answer"
                ref="answerField"
              ></v-text-field>
              <v-card v-show="matchHTML != undefined">
                <v-card-text>
                  <div v-html="matchHTML" class="body-1"></div>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>

          <v-tooltip left v-if="!okAlert && !badAlert">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="float-right mt-2 mb-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="check"
                >Dalej</v-btn
              >
            </template>
            <span>Możesz także nacisnąć <b>Enter</b>.</span>
          </v-tooltip>

          <v-btn
            v-else
            disabled
            class="float-right mt-2 mb-2"
            color="primary"
            @click="check"
            >Dalej</v-btn
          >
        </div>
      </div>
      <!-- END FLASHCARD -->
    </v-col>

    <v-dialog v-model="allDone" max-width="500" persistent>
      <v-card color="success">
        <v-card-title class="white--text">
          <v-icon class="white--text mr-2" large
            >mdi-check-circle-outline</v-icon
          >
          Wszystko zrobione!
        </v-card-title>
        <v-card-text class="white--text"
          >Nauczyłeś(aś) się już wszystkich słówek z tego zestawu.</v-card-text
        >
        <v-card-actions>
          <a
            href="/dashboard/home"
            class="v-btn v-btn--flat v-btn--text theme--light v-size--default white--text"
          >
            <span class="v-btn__content">Koniec</span>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="savedDialog" max-width="500" persistent>
      <v-card color="info">
        <v-card-title class="white--text">
          <v-icon class="white--text mr-2" large
            >mdi-check-circle-outline</v-icon
          >
          Zapisano twoje postępy!
        </v-card-title>
        <v-card-text class="white--text"
          >Możesz wrócić tutaj w dowolnej chwili, aby kontynuować naukę od
          miejsca, w którym skończyłeś(aś).</v-card-text
        >
        <v-card-actions>
          <a
            href="/dashboard/home"
            class="v-btn v-btn--flat v-btn--text theme--light v-size--default white--text"
          >
            <span class="v-btn__content">Koniec</span>
          </a>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="compareModeDialog" v-model="compareModeDialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Uznawanie odpowiedzi
        </v-card-title>

        <v-card-text class="mt-6">
          Twoja odpowiedź jest bardzo podobna do prawidłowej. Czy chcesz ją
          uznać?
          <v-simple-table class="mt-2">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td>Twoja odpowiedź:</td>
                  <td>{{ compareModeData.yourAnswer }}</td>
                </tr>
                <tr>
                  <td>Poprawna odpowiedź:</td>
                  <td>{{ compareModeData.goodAnswer }}</td>
                </tr>
                <tr>
                  <td>Porównanie</td>
                  <td v-html="compareModeData.html"></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-tooltip top v-if="!okAlert && !badAlert">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                text
                @click="handleWrongAnswer(compareModeData.id)"
              >
                Nie uznawaj
              </v-btn>
            </template>
            <span>Możesz także nacisnąć <b>Shift</b>.</span>
          </v-tooltip>

          <v-tooltip top v-if="!okAlert && !badAlert">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                text
                @click="
                  answer = back;
                  compareModeDialog = false;
                  check();
                "
              >
                Uznaj
              </v-btn>
            </template>
            <span>Możesz także nacisnąć <b>Enter</b>.</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar right rounded v-model="recoveredSnackbar" color="success">
      Przywrócono Twoje postępy, ponieważ ostatnim razem została wybrana opcja
      <i>Koniec na dziś</i>. Możesz kontynuować naukę.
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/firebase/init";
import firebase from "firebase";
import DiffMatchPatch from "diff-match-patch";
import stringSimilarity from "string-similarity";

const dmp = new DiffMatchPatch();
export default {
  props: ["id"],
  computed: {
    flashcards() {
      return this.$store.state.flashcards;
    },
  },
  // Shortcuts
  created() {
    window.addEventListener("keydown", this.shortcutListener);
  },
  destroyed() {
    window.removeEventListener("keydown", this.shortcutListener);
  },

  mounted() {
    if (localStorage.savedFlashcardsSession != undefined) {
      const savedData = JSON.parse(localStorage.savedFlashcardsSession);
      this.recoveredSnackbar = true;

      this.session = savedData.session;

      // show first flashcard and reset values
      this.progress = 0;
      this.currentSession = savedData.currentSession;
      this.current = 0;
      this.blocker = false;

      this.flashcardsInSession = 5;
      this.flashcardsInCurrentSession = this.session[
        this.currentSession
      ].length;

      localStorage.removeItem("savedFlashcardsSession");

      this.shuffle();
    } else {
      this.prepareSession();
    }
  },
  data() {
    return {
      current: 0,
      front: "",
      back: "",
      allDone: false,
      blocker: true,
      tempBlocker: false,
      // FLASHCARDS
      headers: [
        { text: "Polski", value: "front" },
        { text: "Angielski", value: "back" },
      ],
      showWordlist: true,
      matchHTML: undefined,
      compareModeData: undefined,
      compareModeDialog: false,
      answer: "",
      okAlert: false,
      badAlert: false,
      progress: 0,
      flashcardsInSession: 0,
      flashcardsInCurrentSession: 0,
      flashcardsAddedToNextSession: 0,
      currentSession: 0,
      session: [],
      recoveredSnackbar: false,
      savedDialog: false,
      // END FLASHCARDS
    };
  },
  methods: {
    prepareSession() {
      this.flashcardsInSession = 5;
      this.flashcardsInCurrentSession = 5;
      // divide flashcards
      for (let i = 0; i < this.flashcards.length; i++) {
        if (i % this.flashcardsInSession == 0) {
          this.session.push([this.flashcards[i]]);
        } else {
          this.session[this.session.length - 1].push(this.flashcards[i]);
        }
      }

      // show first flashcard and reset values
      this.progress = 0;
      this.currentSession = 0;
      this.current = 0;
      this.blocker = false;

      this.shuffle();
    },
    check() {
      let idToChange = this.current;

      let lowerAnswer = this.answer.toLowerCase();
      let lowerBack = this.back.toLowerCase();

      if (lowerAnswer == lowerBack) {
        this.okAlert = true;
        setTimeout(() => {
          this.updateFlashcard({ id: idToChange, goodAnswer: true });
          this.okAlert = false;
          this.answer = "";
          this.$refs.answerField.$refs.input.focus();

          this.updateSessionData();
        }, 1500);
      } else {
        if (stringSimilarity.compareTwoStrings(this.answer, this.back) > 0.6) {
          var d = dmp.diff_main(this.answer, this.back);
          var ds = dmp.diff_prettyHtml(d);

          this.compareModeData = {
            html: ds,
            goodAnswer: this.back,
            yourAnswer: this.answer,
            id: idToChange,
          };

          this.compareModeDialog = true;
        } else {
          this.handleWrongAnswer(idToChange);
        }
      }
    },
    handleWrongAnswer(idToChange) {
      this.compareModeDialog = false;
      this.badAlert = true;

      // CREATING MATCH
      let ds = "";

      if (stringSimilarity.compareTwoStrings(this.answer, this.back) > 0.4) {
        // prepare match
        let d = dmp.diff_main(this.answer, this.back);
        ds = dmp.diff_prettyHtml(d);
      } else {
        ds = `<ins>${this.back}</ins>`;
      }

      this.matchHTML = ds;

      setTimeout(() => {
        this.badAlert = false;
        this.matchHTML = undefined;
        this.answer = "";
        this.$refs.answerField.$refs.input.focus();
        this.updateFlashcard({ id: idToChange, goodAnswer: false });

        // move to next session

        this.session[this.currentSession + 1][
          this.session[this.currentSession + 1].length
        ] = this.session[this.currentSession][this.current];

        this.session[this.currentSession].splice(this.current, 1);
        this.flashcardsInCurrentSession--;
        this.current--;
        this.flashcardsAddedToNextSession++;

        this.updateSessionData();
      }, 4000);
    },
    updateFlashcard(data) {
      if (data.goodAnswer == true) {
        this.session[this.currentSession][this.current].points++;
      }
    },
    updateSessionData() {
      if (!this.blocker) {
        if (
          this.session.length - 1 == this.currentSession &&
          this.current == this.flashcardsInCurrentSession - 1
        ) {
          // finish this session and start another
          this.finishLeraning();
          this.filterFlashcards();

          firebase
            .analytics()
            .logEvent("finish_flashcards_session", { id: this.id });
        } else {
          // next flashcard
          this.current++;

          // Should I start new session?
          if (this.current > this.flashcardsInCurrentSession - 1) {
            // Should I finish learning?
            this.flashcardsInCurrentSession = this.session[
              this.currentSession + 1
            ].length;

            this.current = 0;
            this.currentSession++;
            this.flashcardsAddedToNextSession = 0;

            this.shuffle();
            this.showWordlist = true;
          }

          this.front = this.session[this.currentSession][this.current].front;
          this.back = this.session[this.currentSession][this.current].back;
        }
      }
    },

    finishLeraning() {
      this.allDone = true;
      this.current++;

      // add to firestore
      let usrRef = db.collection("users").doc(firebase.auth().currentUser.uid);

      usrRef
        .update({
          doneExercises: firebase.firestore.FieldValue.arrayUnion(this.id),
        })
        .catch(() => {
          alert(
            "Nie zapisaliśmy twoich postępów, ponieważ wystąpił błąd! Przepraszamy."
          );
        });

      firebase.analytics().logEvent("finish_flashcards_set", { id: this.id });
    },
    shortcutListener(event) {
      if (event.key === "Enter") {
        if (this.showWordlist == true) {
          this.showWordlist = false;
          this.shuffle();
          this.$nextTick(function() {
            // DOM updated
            this.$refs.answerField.$refs.input.focus();
          });
        } else {
          if (this.compareModeDialog == true) {
            this.answer = this.back;
            this.compareModeDialog = false;
          }

          this.check();
        }
      }
      if (event.key === "Shift") {
        this.handleWrongAnswer(this.compareModeData.id);
      }
    },
    shuffle() {
      let val = this.session[this.currentSession];

      let newVal = val.sort(() => Math.random() - 0.5);

      this.session[this.currentSession] = newVal;

      console.log(newVal);

      this.front = this.session[this.currentSession][this.current].front;
      this.back = this.session[this.currentSession][this.current].back;
    },
    finishAndSaveProgress() {
      let go = false;
      if (localStorage.savedFlashcardsSession != undefined) {
        go = confirm(
          "Wcześniej zapisałeś(aś) już jeden zestaw na później. Zapisanie postępu z tego zestawu spowoduje nadpisanie poprzedniego! Kontynuować?"
        );
      } else {
        go = true;
      }

      if (go) {
        localStorage.savedFlashcardsSession = JSON.stringify({
          id: this.id,
          session: this.session,
          currentSession: this.currentSession,
        });

        this.savedDialog = true;
      }
    },
  },
};
</script>

<style>
del {
  color: #ff5252 !important;
  background-color: transparent !important;
}
ins {
  color: #4caf50 !important;
  background-color: transparent !important;
}
</style>
